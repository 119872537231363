import React, { Component } from "react";
import "./Admin.css";
import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etat1: 0,
      etat2: 0,
      etat3: 0,
      etat4: 0,
      etat5: 0,
      etat6: 0,
      etat7: 0,
      etat8: 0,
      etat_satisfaction: 0,
      playerMBS: 0,
      etat_satisfaction3: 0,
      etat_satisfaction4: 0,
    };
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
  }
  componentWillMount() {
    this.db = firebase.database();
  }
  componentDidMount() {}

  AfficherQuizz = () => {
    if (this.state.etat1 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz1BMS")
        .set(1)
        .then(this.setState({ etat1: 1 }));
      console.log("Afficher Quizz 1");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz = () => {
    if (this.state.etat1 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz1BMS")
        .set(0)
        .then(this.setState({ etat1: 0 }));
      console.log("Cacher Quzz 1");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz2 = () => {
    if (this.state.etat2 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz2BMS")
        .set(1)
        .then(this.setState({ etat2: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz2 = () => {
    if (this.state.etat2 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz2BMS")
        .set(0)
        .then(this.setState({ etat2: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz3 = () => {
    if (this.state.etat3 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz3BMS")
        .set(1)
        .then(this.setState({ etat3: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz3 = () => {
    if (this.state.etat3 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz3BMS")
        .set(0)
        .then(this.setState({ etat3: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz4 = () => {
    if (this.state.etat4 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz4BMS")
        .set(1)
        .then(this.setState({ etat4: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz4 = () => {
    if (this.state.etat4 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz4BMS")
        .set(0)
        .then(this.setState({ etat4: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz5 = () => {
    if (this.state.etat5 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz5BMS")
        .set(1)
        .then(this.setState({ etat5: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz5 = () => {
    if (this.state.etat5 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz5BMS")
        .set(0)
        .then(this.setState({ etat5: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz6 = () => {
    if (this.state.etat6 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz6BMS")
        .set(1)
        .then(this.setState({ etat6: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz6 = () => {
    if (this.state.etat6 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz6BMS")
        .set(0)
        .then(this.setState({ etat6: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz7 = () => {
    if (this.state.etat7 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz7BMS")
        .set(1)
        .then(this.setState({ etat7: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz7 = () => {
    if (this.state.etat7 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz7BMS")
        .set(0)
        .then(this.setState({ etat7: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz8 = () => {
    if (this.state.etat8 !== 1) {
      firebase
        .database()
        .ref("iframeQuizz8BMS")
        .set(1)
        .then(this.setState({ etat8: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz8 = () => {
    if (this.state.etat8 !== 0) {
      firebase
        .database()
        .ref("iframeQuizz8BMS")
        .set(0)
        .then(this.setState({ etat8: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherPlayer = () => {
    if (this.state.playerMBS !== 1) {
      firebase
        .database()
        .ref("PlayeriEcho2022")
        .set(1)
        .then(this.setState({ playerMBS: 1 }));
      console.log("Afficher Le player");
    } else alert("le player est déjà affiché");
  };

  CacherPlayer = () => {
    if (this.state.playerMBS !== 0) {
      firebase
        .database()
        .ref("PlayeriEcho2022")
        .set(0)
        .then(this.setState({ playerMBS: 0 }));
      console.log("Satisfaction Cacher");
    } else alert("le player est déjà caché");
  };

  AfficherSatisfaction3 = () => {
    if (this.state.etat_satisfaction3 !== 1) {
      firebase
        .database()
        .ref("satisfactionPlayermedic")
        .set(1)
        .then(this.setState({ etat_satisfaction3: 1 }));
      console.log("Afficher Medic");
    } else alert("le Medic est déjà affiché");
  };

  CacherSatisfaction3 = () => {
    if (this.state.etat_satisfaction3 !== 0) {
      firebase
        .database()
        .ref("satisfactionPlayermedic")
        .set(0)
        .then(this.setState({ etat_satisfaction3: 0 }));
      console.log("Medic Cacher");
    } else alert("le Medic est déjà caché");
  };

  AfficherSatisfaction4 = () => {
    if (this.state.etat_satisfaction4 !== 1) {
      firebase
        .database()
        .ref("satisfactionPlayermarket")
        .set(1)
        .then(this.setState({ etat_satisfaction4: 1 }));
      console.log("Afficher Market");
    } else alert("Market est déjà affiché");
  };

  CacherSatisfaction4 = () => {
    if (this.state.etat_satisfaction4 !== 0) {
      firebase
        .database()
        .ref("satisfactionPlayermarket")
        .set(0)
        .then(this.setState({ etat_satisfaction4: 0 }));
      console.log("Market Cacher");
    } else alert("le Medic est déjà caché");
  };

  render() {
    var template = "";
    console.log(this.props.match.params.token);

    if (this.props.match.params.token === "34EKerh7k6rVKgc552KPk4hkpQMznZ8b") {
      template = (
        <div className="container">
          <h1> Panel d'administration</h1>
          <div className="QuizInteractif">
            <p>
              {" "}
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 1</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz}>
              Afficher Quizz 1
            </button>
            <button className="btn2" onClick={this.CacherQuizz}>
              Cacher Quizz 1
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              {" "}
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 2</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz2}>
              Afficher Quizz 2
            </button>
            <button className="btn2" onClick={this.CacherQuizz2}>
              Cacher Quizz 2
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              {" "}
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 3</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz3}>
              Afficher Quizz 3
            </button>
            <button className="btn2" onClick={this.CacherQuizz3}>
              Cacher Quizz 3
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 4</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz4}>
              Afficher Quizz 4
            </button>
            <button className="btn2" onClick={this.CacherQuizz4}>
              Cacher Quizz 4
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 5</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz5}>
              Afficher Quizz 5
            </button>
            <button className="btn2" onClick={this.CacherQuizz5}>
              Cacher Quizz 5
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 6</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz6}>
              Afficher Quizz 6
            </button>
            <button className="btn2" onClick={this.CacherQuizz6}>
              Cacher Quizz 6
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 7</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz7}>
              Afficher Quizz 7
            </button>
            <button className="btn2" onClick={this.CacherQuizz7}>
              Cacher Quizz 7
            </button>
          </div>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Quizz 8</span>
            </p>
            <button className="btn1" onClick={this.AfficherQuizz8}>
              Afficher Quizz 8
            </button>
            <button className="btn2" onClick={this.CacherQuizz8}>
              Cacher Quizz 8
            </button>
          </div>

          <h3>Mes players</h3>
          <hr></hr>

          <div className="QuizInteractif">
            <p>
              Cette partie permet d'afficher et caher l'iframe des{" "}
              <span> Player</span>
            </p>
            <button className="btn1" onClick={this.AfficherPlayer}>
              Afficher Player
            </button>
            <button className="btn2" onClick={this.CacherPlayer}>
              Cacher Player
            </button>
          </div>
        </div>
      );
    }
    return <div>{template}</div>;
  }
}

export default Admin;
