import React, { Component } from 'react';
import './Header.css';

class Header extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="col-sm-3 col-md-12  stheader">
          <img
            src="https://rdvavenir2021.com/live/IMG/HEADER_MSD12rdvAVENIR.png"
            width="100%"
          />
        </div>
      </div>
    );
  }
}

export default Header;
