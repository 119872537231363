import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="row">
        <div className="stfooter">
          <img
            src="https://rdvavenir2021.com/live/IMG/FOOTER_MSD12rdvAVENIR.png"
            width="100%"
          />
        </div>
      </div>
    );
  }
}

export default Footer;
