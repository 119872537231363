import React, { Component } from "react";
import screenfull from "screenfull";
import "./Player.css";
import Header from "./Header";
import Footer from "./Footer";
import Chat from "./Chat";
import axios from "axios";

import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";
import ReactPlayer from "react-player";
import { io } from "socket.io-client";

var socket = io("https://serveur.lesrencontresnovocure.fr", {
  transports: ["polling"],
  path: "/api/socket.io",
});

class Player extends Component {
  constructor(props, context) {
    super(props, context);

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
    this.state = {
      etat: 2,
      localstate: 2,
      url: null,
      pip: false,
      playing: true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,

      iframeIVI: 0,
      iframeIVI2: 0,
      iframeIVI3: 0,
      iframeIVI4: 0,
      iframeIVI5: 0,
      iframeIVI6: 0,
      iframeIVI7: 0,
      iframeIVI8: 0,

      iframePlayer: 0,
      sessionid: "",
      urlYoutube: "",
      socketCloseiEcho2022: 2,
      PosterGilead: 0,
    };

    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleLocalState = this.handleLocalState.bind(this);
  }
  //
  componentWillMount() {
    this.db = firebase.database();

    // Les Quiz des Iecho

    this.db.ref("iframeQuizz1BMS").on("value", (snap) => {
      this.setState({ iframeIVI: snap.val() });
    });
    /*

    this.db.ref("iframeQuizz2BMS").on("value", (snap) => {
      this.setState({ iframeIVI2: snap.val() });
    });

    this.db.ref("iframeQuizz3BMS").on("value", (snap) => {
      this.setState({ iframeIVI3: snap.val() });
    });

    

    this.db.ref("iframeQuizz4BMS").on("value", (snap) => {
      this.setState({ iframeIVI4: snap.val() });
    });

    this.db.ref("iframeQuizz5BMS").on("value", (snap) => {
      this.setState({ iframeIVI5: snap.val() });
    });

    this.db.ref("iframeQuizz6BMS").on("value", (snap) => {
      this.setState({ iframeIVI6: snap.val() });
    });

    this.db.ref("iframeQuizz7BMS").on("value", (snap) => {
      this.setState({ iframeIVI7: snap.val() });
    });

    this.db.ref("iframeQuizz8BMS").on("value", (snap) => {
      this.setState({ iframeIVI8: snap.val() });
    });*/
    // Fin des Quizz iEcho

    // Url youtube
    this.db.ref("YoutubelinkNOVOCURE").on("value", (snap) => {
      //console.log(snap.val());
      this.setState({ urlYoutube: snap.val() });
    });
    //fin url youtube

    this.db.ref("socketNovocure").on("value", (snap) => {
      this.setState({ socketCloseiEcho2022: snap.val() });
    });

    axios
      .post(`https://serveur.lesrencontresnovocure.fr/api/token/useToken`, {
        token: this.props.match.params.token,
      })
      .then((res) => {
        this.setState({ statusToken: res.status });
      })
      .catch((err) => this.setState({ statusToken: "err" }));

    window.addEventListener("beforeunload", this.callEvent);
  }
  componentDidMount() {
    socket.emit("identify", this.props.match.params.token, function (data) {
      console.log("identify");
      console.log(data);
    });
    //console.log(this.props.match.params.token);
    axios
      .post(`https://serveur.lesrencontresnovocure.fr/api/session/start`, {
        token: this.props.match.params.token,
      })
      .then((res) => {
        this.setState({ idSession: res.data._id });
      });
  }

  //

  componentDidUpdate() {
    if (this.state.socketCloseiEcho2022 === 1) {
      socket.emit("play", this.props.match.params.token, function (data) {});
      console.log("play");
    } else if (this.state.socketCloseiEcho2022 === 0) {
      socket.close();
      console.log("close");
    }
  }

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      localstate: 1,
      pip: false,
    });

    setTimeout(function () {
      screenfull.request();
    }, 200);
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handlePlay = () => {
    this.setState({ playing: true });
  };
  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }

  handleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.request();
    } else {
      //console.log('yes');
    }
  }

  play() {
    this.player.play();
  }
  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  renderLoadButton = (url, label) => {
    return (
      <button className="play-btn" onClick={() => this.load(url)}>
        {label}
      </button>
    );
  };

  ref = (player) => {
    this.player = player;
  };

  handleLocalState() {
    this.setState({ localstate: 1 });
    //console.log('yesy');
  }

  render() {
    const { url, playing } = this.state;

    var templateHide = <div className="ContainerHide"></div>;

    const SEPARATOR = " · ";
    var template2 = "";

    var urlQuizz = "https://serveur.lesrencontresnovocure.fr/quiz/#/pleniere";
    /*var urlQuizz2 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q2&token=" +
      this.props.token;
    var urlQuizz3 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q3&token=" +
      this.props.token;
    var urlQuizz4 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q4&token=" +
      this.props.token;
    var urlQuizz5 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q5&token=" +
      this.props.token;
    var urlQuizz6 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q6&token=" +
      this.props.token;
    var urlQuizz7 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q7&token=" +
      this.props.token;
    var urlQuizz8 =
      "https://serveur.lesrencontresnovocure.fr/overlap/?q=q8&token=" +
      this.props.token;*/

    // var urlSatisfaction = 'https://seminairegileadvih.com/questions/day24/';

    if (this.state.iframeIVI === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz}
          ></iframe>
        </div>
      );
    }

    /* if (this.state.iframeIVI2 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz2}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI3 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz3}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI4 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz4}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI5 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz5}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI6 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz6}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI7 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz7}
          ></iframe>
        </div>
      );
    }

    if (this.state.iframeIVI8 === 1) {
      var template2 = (
        <div className="iframeIVI">
          <iframe
            id="QUIZZ"
            title="QUIZZ"
            width="100%"
            height="100%"
            src={urlQuizz8}
          ></iframe>
        </div>
      );
    }*/

    if (this.state.statusToken === 200) {
      var template1 = (
        <div className="PlayerFullScreen">
          {/*  
           <iframe
            src="//iframe.dacast.com/b/50026/c/468891"
            width="100%"
            height="100%"
            scrolling="no"
            allow="autoplay"
            title="fullPlayer"
          ></iframe>
          
          */}
          <iframe
            width="100%"
            height="100%"
            src={this.state.urlYoutube}
            //src="//iframe.dacast.com/b/50026/c/428043"
            //src="//iframe.dacast.com/live/d6abfb2d213d0c9a2d231908d9336438/1c9e6469ffb02b9d87983b45dad84251"
            // src="//iframe.dacast.com/live/d6abfb2d213d0c9a2d231908d9336438/81992fdc1ab6df9294823e15138db1cc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
          ></iframe>
          {template2}
          <Chat />
          {templateHide}
          <div
            onClick={this.handleFullscreen}
            className="vide"
            // style={{ bottom: this.state.bottomtmp, width: this.state.widthtmp }}
          ></div>
        </div>
      );
    }

    if (this.state.statusToken === "err") {
      template1 = (
        <div className="containerIncorrect">
          <div className="AccesRefuse">Accès refusé</div>
          <hr className="hr"></hr>
          <div className="textIncorrect">
            <div className="first">
              Le lien sur lequel vous avez cliqué semble invalide. Veuillez
              réessayer.
            </div>
            Si vous êtes le médecin inscrit destinataire de ce lien alors vous
            pouvez appeler notre hotline au
            <span style={{ color: "#0000FF" }}> 01 44 90 00 59</span>
          </div>
        </div>
      );
    }

    {
      /**
       *  template1 = (
      <div className="containerIncorrect">
        <div className="AccesRefuse">Accès refusé</div>
        <hr className="hr"></hr>
        <div className="textIncorrect">
          <div className="first">
            Le lien sur lequel vous avez cliqué semble invalide. Veuillez
            réessayer.
          </div>
          Si vous êtes le médecin inscrit destinataire de ce lien alors vous
          pouvez appeler notre hotline au
          <span style={{ color: '#0000FF' }}> 01 44 90 00 59</span>
        </div>
      </div>
    );
       */
    }

    return <div>{template1}</div>;
  }
}

export default Player;
