import React, { Component } from 'react';
import './Home.css';
import './Player';
import Player from './Player';
import axios from 'axios';
import ReactLoading from 'react-loading';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tmpToken: '',
      statusToken: null,
      idSession: '',
    };
  }

  componentWillMount() {
    axios
      .post(`https://rdv-avenir-2021.herokuapp.com/token/useToken`, {
        token: this.props.match.params.token,
      })
      .then((res) => {
        this.setState({ statusToken: res.status });
      })
      .catch((err) => this.setState({ statusToken: 'err' }));

    window.addEventListener('beforeunload', this.callEvent);
  }
  componentDidMount() {
    console.log(this.props.match.params.token);
    axios
      .post(`https://rdv-avenir-2021.herokuapp.com/session/start`, {
        token: this.props.match.params.token,
      })
      .then((res) => {
        this.setState({ idSession: res.data._id });
      });
    window.removeEventListener('beforeunload', this.callEvent);
  }

  callEvent = (e) => {
    const _props = this.props;
    e.preventDefault();

    axios
      .post(`https://rdv-avenir-2021.herokuapp.com/session/end`, {
        session: this.state.idSession,
      })
      .then((res) => {
        console.log('end session' + res);
      });

    /*
    var confirmationMessage = 'o/';
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome*/
  };

  componentWillUnmount() {
    axios
      .post(`https://rdv-avenir-2021.herokuapp.com/session/end`, {
        session: this.state.idSession,
      })
      .then((res) => {
        console.log('end session' + res);
      });
  }

  render() {
    // console.log('session' + JSON.stringify(this.state.idSession));
    // console.log('this.state.statusToken :' + this.state.statusToken);
    var templateHome = '';
    var type = 'spinningBubbles';
    var color = '#01b2e6';

    if (this.state.statusToken === 'err') {
      templateHome = (
        <div className="containerIncorrect">
          <div className="AccesRefuse">Accès refusé</div>
          <hr className="hr"></hr>
          <div className="textIncorrect">
            <div className="first">
              Le lien sur lequel vous avez cliqué semble invalide. Veuillez
              réessayer.
            </div>
            Si le problème de connexion persiste vous pouvez appeler notre
            service hotline au{' '}
            <span style={{ color: '#0000FF' }}> 06 64 79 20 17</span>
          </div>
        </div>
      );
    }
    if (this.state.statusToken === 200) {
      templateHome = (
        <div>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          ></link>

          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.j"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>
          <Player token={this.props.match.params.token} />
        </div>
      );
    }

    if (this.state.statusToken === null) {
      templateHome = (
        <div className="loadingreact">
          {' '}
          <ReactLoading type={type} color={color} height={667} width={375} />
        </div>
      );
    }

    return <div>{templateHome}</div>;
  }
}

export default Home;
